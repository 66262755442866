import axios, {AxiosPromise} from "axios";
// @ts-ignore
import PersonalForm, {PersonalFormErrors} from "@/types/PersonalForm";
// @ts-ignore
import {Stage} from "@/services/employee/EmployeeServise";
// @ts-ignore
import Employee from "@/types/models/Employee";
// @ts-ignore
import AddressForm from "@/types/AddressForm";

export interface StoreResponse {
    success: boolean
    nextStage: Stage|undefined
    errors: PersonalFormErrors|undefined
}

class AddressFormService {
    store(data: AddressForm): Promise<AxiosPromise> {
        return axios.post("employee/address", data as AddressForm);
    }

    back(): Promise<AxiosPromise> {
        return axios.post("employee/address/back");
    }
}

export default new AddressFormService();