<template>
  <form @submit.prevent class="uk-form-stacked">
    <h1>Заявка на подключение самозанятости</h1>
    <h3>Данные регистрации</h3>
    <div class="uk-margin">
      <label class="uk-form-label">Адрес регистрации</label>
      <input class="uk-input" type="text" placeholder="г Москва, ул Сретенка, д 26, кв 1" v-model="formValues.registrationAddress">
      <div class="" v-if="formErrors.registrationAddress">
        <span class="uk-text-danger" v-for="error in formErrors.registrationAddress">{{ error }}</span>
      </div>
    </div>
    <div class="uk-margin">
      <label class="uk-form-label">Адрес фактического проживания совпадает с адресом регистрации</label>
      <input class="uk-checkbox" type="checkbox" v-model="formValues.addressesIsEqual">
      <div class="" v-if="formErrors.addressesIsEqual">
        <span class="uk-text-danger" v-for="error in formErrors.addressesIsEqual">{{ error }}</span>
      </div>
    </div>
    <div class="uk-margin" v-if="!formValues.addressesIsEqual">
      <label class="uk-form-label">Адрес фактического проживания</label>
      <input class="uk-input" type="text" placeholder="г Москва, ул Сретенка, д 26, кв 3" v-model="formValues.factAddress">
      <div class="" v-if="formErrors.factAddress">
        <span class="uk-text-danger" v-for="error in formErrors.factAddress">{{ error }}</span>
      </div>
    </div>

    <button class="uk-button uk-button-primary" @click="submitForm">Сохранить</button>
<!--    <button class="uk-button uk-button-default" @click="turnBack">Назад</button>-->
  </form>
</template>

<script lang="ts">
import {onMounted, ref} from "vue";
// @ts-ignore
import AddressFormService, {StoreResponse} from "@/services/employee/AddressFormService";
// @ts-ignore
import EmployeeService, {Stage, StageResponse} from "@/services/employee/EmployeeServise";
// @ts-ignore
import router from "@/router";

export default {
  setup() {
    const formValues = ref({
      registrationAddress: "",
      factAddress: "",
      addressesIsEqual: false,
    })
    const formErrors = ref({})

    async function checkStage() {
      const response = await EmployeeService.stage();
      const data = response.data.data as StageResponse

      if (data.next != Stage.Address) {
        await router.push({name: `employee/${data.next}`})
      }
    }

    async function submitForm() {
      try {
        const response = await AddressFormService.store(formValues.value);
        await router.push({name: `employee/${response.data.data.next}`})
      } catch (err) {
        const data = err.response.data as StoreResponse;
        if (data.errors) {
          formErrors.value = data.errors;
        }
      }
    }

    async function turnBack() {
      const response = await AddressFormService.back();
      await router.push({name: `employee/${response.data.data.next}`})
    }

    onMounted(async () => {
      await checkStage();
    })

    return {
      formValues,
      formErrors,
      submitForm,
    }
  }
}
</script>